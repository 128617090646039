import React, { useEffect, useState } from 'react';
import { STRING_CONFIG } from 'utils';
// frequently used functions or custom hooks

// functions
export const camelize = (str) => str
  .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
  .replace(/\s+/g, '');

export const notSpecialCharacter = (string) => /[^a-zA-Z0-9]+/g.test(string);

export function getTextForValue(value) {
  if (value >= 0 && value < 2) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.one}`;
  } if (value >= 2 && value < 3) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.two}`;
  } if (value >= 3 && value < 4) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.three}`;
  } if (value >= 4 && value < 5) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.four}`;
  } if (value === 5) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.five}`;
  }
  return 'unknown';
}

export const truncateFullName = (fullName) => {
  if (fullName.length <= 16) {
    return fullName;
  }
  return `${fullName.slice(0, 12)}...`;
};

export function formatNumber(num) {
  if (num < 100) {
    return num.toString();
  } if (num < 1000) {
    return '100+';
  } if (num < 1000000) {
    return `${(num / 1000).toFixed(0)}K+`;
  }
  return '999K+';
}

export function formatDate(dateString) {
  const options = {
    year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
  };
  const date = new Date(dateString);
  return date.toLocaleString('en-IN', options);
}

export function getAdsDurationString(amount) {
  const daysMapping = {
    5: '1 day',
    25: '7 days',
    65: '30 days',
    450: '1 year',
    999: 'forever',
  };
  return `${daysMapping[amount] || 'unknown duration'}`;
}

// custom hooks for background sensitive pop-up
export default function useClickOutside(ref, fun) {
  useEffect(() => {
    const listener = (e) => {
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }
      fun();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref]);
}

// custom hook for Media Queries
export function useMedia(query) {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    const updateMatches = (event) => {
      setMatches(event.matches);
    };

    // Set up the initial state based on the media query
    updateMatches(mediaQuery);

    // Add event listener to update matches when the media query condition changes
    const mediaQueryEventListener = (event) => updateMatches(event);
    mediaQuery.addEventListener('change', mediaQueryEventListener);

    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', mediaQueryEventListener);
    };
  }, [query]);

  return matches;
}

export const useScrollToTop = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);

    // Add event listener for 'popstate' event (when the user navigates within the SPA)
    const onPopState = () => {
      window.scrollTo(0, 0);
    };

    // Attach event listener
    window.addEventListener('popstate', onPopState);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  // You can return any data if needed
  return null;
};

// Custom hook for managing modals
export const useModal = () => {
  const openModalButtonRef = React.useRef(null);
  const [isModalShown, setIsModalShown] = React.useState(false);

  const showModal = () => {
    setIsModalShown(true);
  };

  const hideModal = () => {
    setIsModalShown(false);
    openModalButtonRef.current.focus();
  };

  return {
    openModalButtonRef,
    isModalShown,
    showModal,
    hideModal,
  };
};

import { platformSearch } from 'api/platformRequest';
import React, { useState, useEffect } from 'react';
import { MdSearch } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { clearPlatformSearchInputValue, storePlatformSearchInputValue } from 'store/platform/actions';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';

function SearchInput() {
  const dispatch = useDispatch();
  const inputValueFromStore = useSelector((state) => state.platform.platformSearchInputValue);
  const history = useHistory();
  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

  useEffect(() => {
    const makeApiCall = async () => {
      if (inputValueFromStore.trim().length >= 3) {
        try {
          const { data } = await platformSearch(inputValueFromStore);
          if (data.success === true) {
            setSuggestions(data.results);
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
      }
    };
    makeApiCall();
  }, [inputValueFromStore]);

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => {
        const nextIndex = prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0;
        return nextIndex;
      });
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => {
        const nextIndex = prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1;
        return nextIndex;
      });
    } else if (event.key === 'Enter' && inputValueFromStore.trim() !== '' && inputValueFromStore.length > 2) {
      history.push(`/platform-search/${inputValueFromStore}`);
    }
  };

  useEffect(() => {
    setSelectedSuggestionIndex(-1);
  }, [suggestions]);

  useEffect(() => {
    const clearStoredValue = () => {
      dispatch(clearPlatformSearchInputValue(''));
    };
    window.addEventListener('beforeunload', clearStoredValue);
    return () => {
      window.removeEventListener('beforeunload', clearStoredValue);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div className={`search-input ${isFocused ? 'focused' : null}`} role="search">
        <input
          aria-autocomplete="list"
          aria-label="Search Input"
          aria-owns={suggestions.length > 0 ? 'suggestions-list' : null}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => dispatch(storePlatformSearchInputValue(e.target.value))}
          onFocus={() => setIsFocused(true)}
          onKeyDown={handleKeyDown}
          placeholder={STRING_CONFIG.inPage.common.plHNavBarSearch}
          type="text"
          value={inputValueFromStore}
        />
        <MdSearch
          color={isFocused ? DESIGN_CONFIG.color.primary.primaryMain : DESIGN_CONFIG.color.text.text200}
          size={24}
        />
      </div>
      {isFocused && inputValueFromStore && suggestions.length > 0 && (
        <ul
          className="suggestions"
          id="suggestions-list"
          role="listbox"
        >
          {suggestions.slice(0, 5).map((result, index) => (
            <li
              aria-selected={selectedSuggestionIndex === index ? 'true' : 'false'}
              className={`list-item ${selectedSuggestionIndex === index ? 'selected' : null}`}
              key={result.product_id}
              onMouseDown={(event) => {
                event.preventDefault();
                history.push(`/platform-search/${inputValueFromStore}`);
              }}
              role="option"
            >
              <img alt="" className="image" src={result.productImage} />
              <div className="product-details">
                <p style={{
                  color: `${DESIGN_CONFIG.color.text.text500}`,
                  fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`,
                  fontWeight: `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontWeight}`,
                  margin: 0,
                }}
                >
                  {result.variant}
                </p>
                <p style={{
                  color: `${DESIGN_CONFIG.color.text.text100}`,
                  fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`,
                  fontWeight: `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontWeight}`,
                  margin: 0,
                }}
                >
                  {result.model}
                </p>
                <div style={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '.5rem',
                }}
                >
                  <div style={{
                    background: `${DESIGN_CONFIG.color.primary.primaryMain}`,
                    display: 'flex',
                    gap: '.25rem',
                    padding: '.25rem',
                  }}
                  >
                    <img
                      alt=""
                      height={20}
                      src="/assets/whiteStartSearchModal.svg"
                      width={12}
                    />
                    <p style={{
                      color: `${DESIGN_CONFIG.color.neutral.neutral500}`,
                      fontSize: `${DESIGN_CONFIG.typography.common.webTag.fontSize}`,
                      fontWeight: `${DESIGN_CONFIG.typography.common.webTag.fontWeight}`,
                      margin: 0,
                    }}
                    >
                      {result.productValue}
                    </p>
                  </div>
                  <p style={{
                    color: `${DESIGN_CONFIG.color.text.text100}`,
                    fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`,
                    fontWeight: `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontWeight}`,
                    margin: 0,
                  }}
                  >
                    •
                    {result.reviewCount}
                    {' '}
                    {STRING_CONFIG.inPage.product.txtReviewTitle}
                  </p>
                </div>

              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchInput;

import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound404 from 'assets/NotFound404.svg';
import internalServerError from 'assets/internalServerError.svg';
import { CircularProgress } from '@mui/material';
import UserCampaign from 'Pages/InProgress';
import SearchSmallScreen from 'Pages/SearchSmallScreen/SearchSmallScreen';
import PrivateRoute from './PrivateRoute';
import RestrictedRoute from './RestrictedRoute';
import { STRING_CONFIG } from 'utils';

const ProductCompare = lazy(() => import('Pages/ProductCompare'));
const About = lazy(() => import('Pages/About'));
const Articles = lazy(() => import('Pages/Articles'));
const Browse = lazy(() => import('Pages/Browse'));
const ChangePassword = lazy(() => import('Pages/ChangePassword'));
const VerifyForgotPassword = lazy(() => import('Pages/VerifyForgotPassword'));
const EmailVerificationCheckEmail = lazy(() => import('Pages/EmailVerificationCheckEmail'));
const SignUpEmailVerification = lazy(() => import('Pages/SignUpEmailVerification'));
const ErrorPages = lazy(() => import('ReusableComponents/ErrorPages'));
const Help = lazy(() => import('Pages/Help'));
const HelpMyAccount = lazy(() => import('Pages/Help/HelpMyAccount'));
const HelpMyDonations = lazy(() => import('Pages/Help/HelpMyDonations'));
const HelpMyProductReviews = lazy(() => import('Pages/Help/HelpMyProductReviews'));
const HelpNewFeatureRequest = lazy(() => import('Pages/Help/HelpNewFeatureRequest'));
const HelpReportBug = lazy(() => import('Pages/Help/HelpReportBug'));
const HelpReportPolicyViolation = lazy(() => import('Pages/Help/HelpReportPolicyViolation'));
const InitiateForgotPassword = lazy(() => import('Pages/InitiateForgotPassword'));
const MostLikedProducts = lazy(() => import('Pages/ProductsByCategory/MostLikedProducts'));
const MostReviewedProducts = lazy(() => import('Pages/ProductsByCategory/MostReviewedProducts'));
const MostViewedProducts = lazy(() => import('Pages/ProductsByCategory/MostViewedProducts'));
const PlatformSearch = lazy(() => import('Pages/PlatformSearch'));
const PrivacyPolicy = lazy(() => import('Pages/PrivacyPolicy'));
const ProductDetails = lazy(() => import('Pages/ProductDetails'));
const QuestionDetails = lazy(() => import('Pages/QuestionDetails'));
const ReportUser = lazy(() => import('Pages/ReportUser'));
const ReviewDetails = lazy(() => import('Pages/ReviewDetails'));
const UserAuth = lazy(() => import('Pages/UserAuth'));
const TermsConditions = lazy(() => import('Pages/TermsConditions'));
const UserProfile = lazy(() => import('Pages/UserProfile'));

function Routes() {
  const Style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  };

  return (
    <Suspense fallback={(<div style={Style}><CircularProgress /></div>)}>
      <Switch>
        {/* public routes start */}
        <Route exact path="/browse" component={Browse} />
        <Route exact path="/create-new-password/:token" component={VerifyForgotPassword} />
        <Route path="/email-verification" component={EmailVerificationCheckEmail} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/help-my-account" component={HelpMyAccount} />
        <Route exact path="/help-my-product-reviews" component={HelpMyProductReviews} />
        <Route exact path="/help-my-donations" component={HelpMyDonations} />
        <Route exact path="/help-report-policy-violation" component={HelpReportPolicyViolation} />
        <Route exact path="/help-report-bug" component={HelpReportBug} />
        <Route exact path="/help-new-feature-request" component={HelpNewFeatureRequest} />
        <Route exact path="/homepage/:token" component={SignUpEmailVerification} />
        <Route exact path="/initiate-forgot-password" component={InitiateForgotPassword} />
        <Route exact path="/product/:id" component={ProductDetails} />
        <Route path="/profile/:username" component={ReportUser} />
        <Route exact path="/platform-search/:query" component={PlatformSearch} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/product/:id/question/:qid" component={QuestionDetails} />
        <Route path="/product/:id/review/:rid" component={ReviewDetails} />
        <Route exact path="/terms-and-conditions" component={TermsConditions} />
        <Route exact path="/user-campaign" component={UserCampaign} />
        <Route exact path="/search" component={SearchSmallScreen} />
        <Route exact path="/articles" component={Articles} />
        <Route exact path="/compare" component={ProductCompare} />
        {/* public routes end */}

        {/* Restricted routes start */}
        <RestrictedRoute exact path="/" component={About} />
        <RestrictedRoute exact path="/sign-in" component={UserAuth} />
        {/* Restricted routes end */}

        {/* protected routes start */}
        <PrivateRoute exact path="/about-reviewdale" component={About} />
        <PrivateRoute exact path="/change-password" component={ChangePassword} />
        <PrivateRoute exact path="/most-viewed-products" component={MostViewedProducts} />
        <PrivateRoute exact path="/most-reviewed-products" component={MostReviewedProducts} />
        <PrivateRoute exact path="/most-liked-products" component={MostLikedProducts} />
        <PrivateRoute exact path="/user-profile" component={UserProfile} />
        {/* protected routes end */}

        {/* Error route */}
        <Route
          path="/internal-server-error"
          component={() => (
            <ErrorPages
              title={STRING_CONFIG.inPage.common.txtError500Title}
              subtitle={STRING_CONFIG.inPage.common.txtError500Title.txtError500Subtext}
              img={internalServerError}
            />
          )}
        />
        <Route
          path="*"
          component={() => (
            <ErrorPages
              title={STRING_CONFIG.inPage.common.txtError404Title}
              subtitle={STRING_CONFIG.inPage.common.txtError404Subtext}
              img={NotFound404}
            />
          )}
        />
        <Route
          path="/page-not-found"
          component={() => (
            <ErrorPages
              title={STRING_CONFIG.inPage.common.txtError404Title}
              subtitle={STRING_CONFIG.inPage.common.txtError404Subtext}
              img={NotFound404}
            />
          )}
        />
      </Switch>
    </Suspense>
  );
}

export default Routes;

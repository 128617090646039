import React, { useEffect, useState } from 'react';
import Header from 'Pages/Home/Components/Trending/Header';
import AdTray from 'Pages/Components/AdTray';
import TopBar from 'Pages/ProductDetails/Topbar';
import Footer from 'Pages/Footer';
import TopbarSmall from 'Pages/ProductDetails/Topbar/TopbarSmall';
import { Helmet } from 'react-helmet';
import { useMedia } from 'helpers';
import './style.css';
import { CircularProgress } from '@mui/material';
import { getCategoryProductList } from 'api/platformAnalyticsRequest';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { STRING_CONFIG } from 'utils';
import CategorySection from './Components/CategorySection';

function Home() {
  const Style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  };
  const showAdTray = useSelector((state) => state.auth.adTray.show);
  const history = useHistory();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const smallDevice = useMedia('(max-width:450px)');
  const requestError = (msg) => {
    Modal.error({
      content: msg,
    });
  };
  useEffect(() => {
    fetchProductList();
  }, []);

  const fetchProductList = async () => {
    try {
      setLoading(true);
      const { data } = await getCategoryProductList('C001');
      if (data.success === true) {
        setData(data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err.response) {
        if (err.response.status === 400) {
          history.push('/page-not-found');
        } else {
          history.push('/internal-server-error');
        }
      } else if (err.request) {
        requestError(STRING_CONFIG.inPage.toastMessages.txtErrorSomethingWentWrong);
      }
    }
  };
  if (!data) {
    return (
      <div style={Style}>
        <CircularProgress />
      </div>
    );
  }
  if (data) {
    return (
      <>
        {smallDevice ? <TopbarSmall /> : <TopBar />}
        <div className="top-page-container">
          <main className="home">
            {/* {showAdTray ? <AdTray /> : null} */}
            <Header />
            <div style={{ marginBottom: '2rem' }}>
              <CategorySection data={data.most_viewed} name={STRING_CONFIG.inPage.home.txtSectionTwoTitle} one />
              <CategorySection data={data.most_reviewed} name={STRING_CONFIG.inPage.home.txtSectionThreeTitle} two />
              <CategorySection data={data.most_upvoted} name={STRING_CONFIG.inPage.home.txtSectionFourTitle} three />
            </div>
          </main>
        </div>
        <Footer />
        <Helmet>
          <title>{STRING_CONFIG.inPage.common.txtHeaderReviewDale}</title>
          <meta
            name="description"
            content="With the vast number of options and features available today, we understand that choosing a phone can be tricky. That's why we created ReviewDale: A Product Community, to make it easier for you and everyone to share experiences with various phones, so go ahead and indulge!"
          />
          <meta name="keywords" content="Product, Reviews, Genuine Reviews" />
        </Helmet>
      </>
    );
  }
}

export default Home;
